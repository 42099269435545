<template>
  <div class="banner-content">
    <v-row no-gutters class="hidden-md-and-up mb-bg-color">
      <flicking
          ref="flicking1"
          class="flicking flicking1 mt-3 mb-2"
          :options="{ circular: true, gap: 20, resizeOnContentsReady: true, moveType:'snap',  iOSEdgeSwipeThreshold: 30, preventClickOnDrag: false}"
          :plugins="this.plugins"
      >
        <div v-for="(banner, i) in bannerContent" :key="i" class="mx-1 flicking-panel mob_banner">
          <img :src="banner.mobile_banner" width="320" height="150" class="jaya9_panel"
               @click="callBannerAction(banner)"/>
        </div>
        <div slot="viewport" class="flicking-pagination">

        </div>
      </flicking>
    </v-row>

    <v-row no-gutters class="hidden-sm-and-down">
      <v-col cols="12">
        <v-carousel cycle height="auto" hide-delimiter-background delimiter-icon="mdi-minus" :show-arrows="false"
                    interval="5000"
                    class="home-banner-carousel hidden-sm-and-down">
          <!-- <v-carousel class="home-banner-carousel-mobile hidden-sm-and-down" delimiter-icon="mdi-minus" :show-arrows="false" cycle interval="10000" height="auto" height="420px"> -->
          <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
            <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner)" :aspect-ratio="1920/467">
              <!-- <v-overlay class="banner-overlay" :absolute="true" :value="true" :opacity="0">
                  <div class="ma-10">
                      <h1>{{banner.title}}</h1>
                      <p>{{banner.desc}} </p>
                      <app-button class="apply-button" :action="callBannerAction" :title="$t(`label.applyAsAffiliateAgent`)"></app-button>
                  </div>
              </v-overlay> -->
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <!-- <app-sport-casino-holder></app-sport-casino-holder> -->
    </v-row>

    <div class="marquee-row">
      <div class="marquee-wrapper">
        <div class="marquee-icon"></div>
        <marquee class="marquee">
          <template v-for="announcement in filteredAnnouncement">
            <span :key="announcement.id" class="pr-6 mr-6">
              <label class="d-inline-block home-announcment-content">{{ announcement.title }}</label>
              <label class="d-inline-block home-announcment-content"
                     v-html="announcement.message != null ? ' :' + announcement.message.replace(/<[p>]*>/g, ' ') : ''"></label>
            </span>
          </template>
        </marquee>
      </div>
    </div>

  </div>
</template>

<script>
import {Sync} from "@egjs/flicking-plugins";
import {Pagination, Fade} from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import {
  SHARED
} from '@/constants/constants'
import {
  uiHelper
} from '@/util'
import {
  DevicePlatform
} from '@/constants/enums'
import axios from 'axios'
import AppSportCasinoHolder from '@/components/home/SportCasinoHolder.vue'
import {
  CMS_BANNER_ACTIVE
} from "@/store/cms.module";
import {AutoPlay} from "@egjs/flicking-plugins";

export default {
  name: 'appHomeBanner',
  props: {
    filteredAnnouncement: {
      type: Array,
      required: true,
      default: []
    }
  },
  data: () => ({
    plugins: [],
    routeName: ROUTE_NAME,
    banners: [],
    mobilebanners: [],
    bannerIndex: 0,
    currentLanguage: uiHelper.getLanguage(),
    mediaUrl: SHARED.MEDIA_SERVER_URL
  }),
  components: {
    AppSportCasinoHolder
  },
  mounted() {
    this.plugins =
        [new AutoPlay({
          duration: 3000,
          direction: "NEXT",
          stopOnHover: false
        }),
          new Pagination({
            type: 'bullet', renderBullet: function () {
              return '<span class="paging-bullet"><i></i><b></b></span>'
            }
          }),
          new Sync({
            type: "index",
            synchronizedFlickingOptions: [
              {
                flicking: this.$refs.flicking1,
                isClickable: true,
                isSlidable: true,
                activeClass: "active"
              }
            ]
          })];
  },
  computed: {
    bannerContent() {
      return this.$store.state.cms.bannerContent
      // return [{
      //         action: "",
      //         desc: "BONUS 88888",
      //         desktop_banner: "/static/image/banner/en/home_banner.png",
      //         mobile_banner: "/static/image/banner/en/home_banner_m.png",
      //         title: "Welcome Bonus 888",
      //     },
      //     {
      //         action: "",
      //         desc: "BONUS 88888",
      //         desktop_banner: "/static/image/banner/en/home_banner.png",
      //         mobile_banner: "/static/image/banner/en/home_banner_m.png",
      //         title: "Welcome Bonus 888",
      //     },
      //     {
      //         action: "",
      //         desc: "BONUS 88888",
      //         desktop_banner: "/static/image/banner/en/home_banner.png",
      //         mobile_banner: "/static/image/banner/en/home_banner_m.png",
      //         title: "Welcome Bonus 888",
      //     },
      // ]
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  created() {
    this.getCmsBanner()
    // this.generateDesktopBanner()
    // this.generateMobileBanner()
  },
  methods: {
    callBannerAction(action) {
      try {
        let s = action.action.replaceAll("'", '"');
        let jsonData = JSON.parse(s);
        this.bannerAction({
          'action': jsonData['action'],
          'action_url': action.action_url,
          'bonusId': ''
        })
      } catch (err) {
        this.bannerAction({
          'action': 'promotionpage',
          'bonusId': ''
        })
      }
    },
    getCmsBanner() {
      let obj = {
        language: uiHelper.getLanguage()
      }
      this.$store.dispatch(`${CMS_BANNER_ACTIVE}`, {
        obj
      })
    },
    generateDesktopBanner() {
      axios({
        method: 'GET',
        url: SHARED.DESKTOP_BANNER_JSON_URL,
        responseType: 'json'
      }).then(response => {
        if (response.status == 200) {
          response.data.forEach(banner => {
            this.banners.push({
              src: banner.src,
              action: banner.action,
              bonusId: banner.bonusId
            })
          })
        }
      })
    },
    generateMobileBanner() {
      axios({
        method: 'GET',
        url: SHARED.MOBILE_BANNER_JSON_URL,
        responseType: 'json'
      }).then(response => {
        if (response.status == 200) {
          response.data.forEach(banner => {
            this.mobilebanners.push({
              src: banner.src,
              action: banner.action
            })
          })
        }
      })
    },
    bannerAction(banner) {
      let action = banner.action
      let action_url = banner.action_url
      let bonusId = banner.bonusId
      switch (action.toLowerCase()) {
        case 'register':
          if (!this.isLoggedIn) {
            this.$parent.openRegisterDialog()
          } else {
            this.$router.push({
              name: ROUTE_NAME.PROMOTION
            })
          }
          break
        case 'livecasinopage':
          this.$router.push({
            name: ROUTE_NAME.CASINO
          })
          break
        case 'urlpage':
          window.open(action_url, '_blank')
          break
        case 'sportsbookpage':
          this.$router.push({
            name: ROUTE_NAME.SPORTSBOOK
          })
          break
        case 'paynowdeposit':
          if (!this.isLoggedIn) {
            this.$parent.openLoginDialog()
          } else {
            this.$router.push({
              name: ROUTE_NAME.DEPOSIT
            })
          }
          break;
        case 'rewards':
          this.$router.push({
            name: ROUTE_NAME.REWARDS_BY_TYPE,
            params: {
              type: 'luckyspin'
            }
          })
          break;
        case 'promotionpage':
          this.$router.push({
            name: ROUTE_NAME.PROMOTION,
          })
          break
        case 'slotpage':
          this.$router.push({
            name: ROUTE_NAME.SLOT
          })
          break
        case 'affiliatepage':
          this.$router.push({
            name: ROUTE_NAME.AFFILIATE_HOME
          })
          break
        case 'launchmjsgame':
          if (!this.isLoggedIn) {
            this.$parent.openLoginDialog()
          } else {
            if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
              let routeData = this.$router.resolve({
                name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
                params: {
                  provider: 'mjs',
                  type: 'sb'
                },
                query: {
                  demo: 0
                }
              })
              uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1200, 900)
            } else {
              this.$router.push({
                name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
                params: {
                  provider: 'mjs'
                }
              })
            }
          }
          break
      }
    }
  }
}
</script>

<style lang="scss">
.apply-button {
  flex-grow: 1;
  width: 120px;
  height: 90px;
  padding: 9px !important;

  .v-btn__content {
    color: black;
  }
}

.banner-content {
  height: auto;
  position: relative;

  button {
    color: #ffffff !important;
    margin: 0px;
    padding: 0px;
  }

  .home-banner-carousel {
    cursor: pointer;
  }

  .home-banner-carousel-mobile {
    .v-image {
      cursor: pointer;
    }
  }

  .banner-overlay {
    height: auto;
    width: 35%;
    display: block;
    margin-left: auto;
  }
}

.marquee-row {
  background-color: #3539A9;
  color: var(--v-text_reverse-base);

  .home-announcment-content {
    font-size: 16px
  }

  .marquee-wrapper {
    display: flex;
    align-items: center;
    max-width: 1240px;
    margin: auto;

    .marquee {
      width: calc(100% - 60px);
      display: flex;
      align-items: center;
    }
  }

  .marquee-icon {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin: 4px 8px;
    background-color: #ffffff;
    mask: url(/static/svg/announcement-icon.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask: url(/static/svg/announcement-icon.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
  }

}

@media (max-width: 599px) {

  .banner-content {
    .banner-overlay {
      height: auto;
      width: 50%;
      display: block;
      margin-left: auto;
    }

    .v-carousel__controls {
      position: unset;
      height: auto;
    }
  }

}

.mb-bg-color {
  border-bottom: 0.3px solid black;
  background-color: #142590;
}

.mb-br-radius {
  border-radius: 10px;
}

.flicking-panel {
  opacity: 0.5;
  zoom: 1.0;
}

.flicking-panel.active {
  opacity: 1;
}

.JAYA9_panel {
  border-radius: 1.3333333333vw;
  box-shadow: 0 0.5333333333vw 0.8vw rgb(0 0 0 / 30%);
}

.paging-bullet {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 5px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 1rem;

  i {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 5px;
    border-radius: 20%;
    background-color: rgb(10 10 10 / 10%);
    //progress bar 배경
  }

  b {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 0%;
    height: 5px;
    background-color: rgba(17, 17, 17, 1);
    //progress bar 채워지는 부분
  }
}

.flicking-pagination-bullet-active {
  background-color: rgb(10 10 10 / 10%);

  b {
    background-color: #f56909;
    animation-name: countingBar;
    animation-duration: 3.5s; //autopaly 시간과 맞춰주기
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: alternate;
  }
}

@keyframes countingBar {
  0% {
    width: 0;
  }
  100% {
    width: 20px
  }
}

.flicking-pagination {
  position: relative;
  text-align: center;
  bottom: 0;
}

.flicking-pagination-bullets, .flicking-pagination-scroll {
  font-size: 0;
}

.flicking-viewport {
  position: relative;
  overflow: hidden
}

.flicking-viewport:not(.vertical) {
  width: 100%;
  height: 100%
}

.flicking-camera {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  will-change: transform
}

.flicking-camera > * {
  display: inline-block;
  white-space: normal;
  vertical-align: top
}

.flicking-viewport.vertical, .flicking-viewport.vertical > .flicking-camera {
  display: inline-block
}

.flicking-viewport.vertical.middle > .flicking-camera > * {
  vertical-align: middle
}

.flicking-viewport.vertical.bottom > .flicking-camera > * {
  vertical-align: bottom
}

.flicking-viewport.vertical > .flicking-camera > * {
  display: block
}

.flicking-viewport.flicking-hidden > .flicking-camera > * {
  visibility: hidden
}
</style>
