<template>
  <div :class="$vuetify.breakpoint.smAndDown ? 'px-4 py-6' : 'py-6'" class="highlight-container">
    <div class="highlight-wrapper">
      <!-- <div class="matches-box-desktop d-none d-md-flex">
      <div class="matches-box-desktop-1">
          <div class="matches-box-header">
              <div class="matches-box-header-title">{{ $t(`label.favourite`) }}</div>
              <div class="matches-box-header-control">
                  <v-icon class="header-icon" @click="previous_1">arrow_back</v-icon>
                  <div class="header-divider"></div>
                  <v-icon class="header-icon" @click="next_1">arrow_forward</v-icon>
              </div>
          </div>
          <div class="matches-box-body">
              <v-carousel v-model="currentHightlight_1" :continuous="true" :cycle="false" :show-arrows="false" :hide-delimiter-background="true" :hide-delimiters="true" height="397">
                  <v-carousel-item v-for="(league, index) in desktopHighlights_1" :key="index" :href="routeName.CRICKET">
                      <div class="match-item-desktop">
                          <div class="league-name yellow--text">{{ league.league_name }}</div>
                          <div class="match-date">{{ league.match_date }}</div>
                          <div class="match-team">
                              <div class="home-team">
                                  <div>{{ league.home_team }}</div>
                                  <div>{{ parseFloat(league.home_odds).toFixed(2) }}</div>
                              </div>
                              <img width="42px" height="42px" src="/static/image/favourite/vs.png" alt class="vs-team" />
                              <div class="away-team">
                                  <div>{{ league.away_team }}</div>
                                  <div>{{ parseFloat(league.away_odds).toFixed(2) }}</div>
                              </div>
                          </div>
                      </div>
                  </v-carousel-item>
              </v-carousel>
          </div>
      </div>
      <div class="matches-box-desktop-2">
          <div class="matches-box-header">
              <div class="matches-box-header-title">{{ $t(`label.favourite`) }}</div>
              <div class="matches-box-header-control">
                  <v-icon class="header-icon" @click="previous_2">arrow_back</v-icon>
                  <div class="header-divider"></div>
                  <v-icon class="header-icon" @click="next_2">arrow_forward</v-icon>
              </div>
          </div>
          <div class="matches-box-body">
              <v-carousel v-model="currentHightlight_2" :continuous="false" :cycle="false" :show-arrows="false" :hide-delimiter-background="true" :hide-delimiters="true" height="397">
                  <v-carousel-item v-for="(league, index) in desktopHighlights_2" :key="index" :href="routeName.CRICKET">
                      <div class="match-item-desktop">
                          <div class="league-name yellow--text">{{ league.league_name }}</div>
                          <div class="match-date">{{ league.match_date }}</div>
                          <div class="match-team">
                              <div class="home-team">
                                  <div>{{ league.home_team }}</div>
                                  <div>{{ parseFloat(league.home_odds).toFixed(2) }}</div>
                              </div>
                              <img width="42px" height="42px" src="/static/image/favourite/vs.png" alt class="vs-team" />
                              <div class="away-team">
                                  <div>{{ league.away_team }}</div>
                                  <div>{{ parseFloat(league.away_odds).toFixed(2) }}</div>
                              </div>
                          </div>
                      </div>
                  </v-carousel-item>
              </v-carousel>
          </div>
      </div>
  </div> -->

      <!-- <div class="matches-label mb-4 d-md-none">{{ $t(`label.favourite`) }}</div> -->
      <div class="matches-box">
        <div v-for="(league, index) in matchesHighlights" :key="index">
          <div v-if="matchUp(league) == 'Live'" class="matchUp">
            <div>Live</div>
            <img width="70" src="/static/image/favourite/now.webp"/>
          </div>
          <div v-else-if="matchUp(league) == 'Pre-match'" class="matchUp">
            <div>Pre-Match</div>
          </div>
          <div v-else class="matchUp">
            <div>Match Hot</div>
            <img width="70" src="/static/image/favourite/hot.webp"/>
          </div>
          <a class="match-item mr-5" :href="routeName.CRICKET">
            <div class="league-name">{{ league.league_name }}</div>
            <div class="match-date">{{ league.match_date }}</div>
            <div class="match-team">
              <div class="home-team">
                <!-- <v-img :aspect-ratio="311/234" class="game-card-image-avatar" :src="`${league.home_logo}`" alt></v-img> -->
                <div>{{ league.home_team }}</div>
                <div>{{ parseFloat(league.home_odds).toFixed(2) }}</div>
              </div>
              <!-- <div class="vs-team blue--text">VS</div> -->
              <!-- <img scr="/static/image/favourite/vs.png" width="26px" height="26px"/> -->
              <!-- <img width="42px" height="42px" src="/static/image/favourite/vs.png" alt class="vs-team" /> -->
              <div class="away-team">
                <!-- <v-img :aspect-ratio="311/234" class="game-card-image-avatar" :src="`${league.away_logo}`" alt></v-img> -->
                <div>{{ league.away_team }}</div>
                <div>{{ parseFloat(league.away_odds).toFixed(2) }}</div>
              </div>
            </div>

            <!-- <v-img width="200" height="200" class="game-card-image-avatar" src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg" alt/> -->
            <!-- <v-row no-gutters class="flex-nowrap">
    <v-col cols="12">
        <v-row no-gutters>
        <v-col>
            <v-card
            class="pa-2 mb-1 mr-1 font-weight-bold cricket-name-header"
            :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
            dark
            outlined
            tile
            >
            <v-icon dense class="color-base-yellow mb-1 mr-3">emoji_events</v-icon>
            {{ league.league_name }}
            </v-card>
        </v-col>
        </v-row>
        <v-row no-gutters>
        <v-col cols="9">
            <v-card
            light
            class="pa-2 mb-1 mr-1 grey lighten-4"
            :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
            outlined
            tile
            >
            {{ league.home_team }}
            </v-card>
        </v-col>
        <v-col cols="3">
            <v-card
            light
            class="pa-2 mb-1 mr-1 grey lighten-4"
            :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
            outlined
            tile
            >
            {{ parseFloat(league.home_odds).toFixed(2) }}
            </v-card>
        </v-col>
        <v-col cols="9">
            <v-card
            light
            class="pa-2 mb-1 mr-1 grey lighten-4"
            :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
            outlined
            tile
            >
            {{ league.away_team }}
            </v-card>
        </v-col>
        <v-col cols="3">
            <v-card
            light
            class="pa-2 mb-1 mr-1 grey lighten-4"
            :class="index == 0 ? 'border-top-left-radius' : index == matchesHighlights.length - 1 ? 'border-top-right-radius' : ''"
            outlined
            tile
            >
            {{ parseFloat(league.away_odds).toFixed(2) }}
            </v-card>
        </v-col>
        </v-row>
        <v-row no-gutters>
        <v-col cols="12">
            <v-card
            light
            class="pa-2 grey lighten-4 mr-1"
            :class="index == 0 ? 'border-bottom-left-radius' : index == matchesHighlights.length - 1 ? 'border-bottom-right-radius' : ''"
            outlined
            tile
            >
            <v-row no-gutters>
                <v-col cols="9">
                {{ league.match_date }}
                </v-col>
                <v-col cols="3" class="text-right">
                <v-btn color="black" class="primary-button embedded-mobile-login-button font-weight-bold yellow--text" :to="{ name: routeName.CRICKET }">{{ $t(`button.betNow`) }}</v-btn>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
        </v-row>
    </v-col>
    </v-row> -->
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash'
import stingFormat from 'string-format'
import moment from 'moment'
import {
  formValidator,
  uiHelper
} from '@/util'
import {
  SHARED
} from '@/constants/constants'
import {
  GAME_MATCHES_HIGHLIGHTS
} from '@/store/game.module'
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import {
  locale
} from '@/util/localization-helper'

export default {
  name: 'appHomeHighlight',
  data: () => ({
    highlights: null,
    routeName: ROUTE_NAME,
    desktopHighlights_1: [],
    desktopHighlights_2: [],
    currentHightlight_1: 0,
    currentHightlight_2: 0,
  }),
  created() {
    this.getMatchesHighlights()
  },
  computed: {
    matchesHighlights() {
      let k = this.$store.state.game.matchesHighlights

      const today = new Date();
      const todayDateOnly = today.toISOString().split('T')[0];
      k = k.filter(match => new Date(match.match_date).toISOString().split('T')[0] >= todayDateOnly);
      k = _.orderBy(k, ['match_date'], ['asc'])
      k = _.take(k, 7)
      const n = Math.ceil(k.length / 2)
      this.desktopHighlights_1 = k.slice(0, n)
      this.desktopHighlights_2 = k.slice(n)

      return (_.take(k, 6))
    },
  },
  /*watch: {
    matchesHighlights() {
      this.highlights = this.$store.state.game.matchesHighlights
      return this.highlights
    }
  },*/
  methods: {
    next_1() {
      this.currentHightlight_1 += 1
    },
    previous_1() {
      if (!this.currentHightlight_1)
        this.currentHightlight_1 = this.desktopHighlights_1.length - 1
      else this.currentHightlight_1 -= 1
    },
    next_2() {
      this.currentHightlight_2 += 1
    },
    previous_2() {
      if (!this.currentHightlight_2)
        this.currentHightlight_2 = this.desktopHighlights_2.length - 1
      else this.currentHightlight_2 -= 1
    },
    changeLanguage(language) {
      this.$store.dispatch(`${SHARED.LOADING}`)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
      window.location.reload()
    },
    getMatchesHighlights() {
      this.$store.dispatch(`${GAME_MATCHES_HIGHLIGHTS}`)
    },
    matchUp(league) {
      if (moment(league.match_date).isSame(new Date(), 'day')) {
        return 'Live'
      } else if (moment(league.match_date).isAfter(new Date(), 'day')) {
        return 'Pre-match'
      } else {
        return 'Hot'
      }
    }
  }
}
</script>

<style lang="scss">
.highlight-container {
  background-color: var(--v-text_reverse-base);

  .highlight-wrapper {
    max-width: 1240px;
    margin: auto;

    .matches-box-desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      color: var(--v-text_reverse-base);

      .matches-box-desktop-1,
      .matches-box-desktop-2 {
        width: 100%;

        .matches-box-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: var(--v-background_2-base);
          height: 40px;
          border-radius: 5px 5px 0px 0px;

          .matches-box-header-title {
            margin: 12px;
          }

          .matches-box-header-control {
            display: flex;
            margin-right: 12px;

            .header-divider {
              height: 40px;
              width: 1px;
              background: rgba(255, 255, 255, 0.3);
              margin: 0px 4px;
            }

            .header-icon {
              color: #ffa60c;
              cursor: pointer;
            }

            .v-icon.v-icon::after {
              background-color: unset;
            }
          }
        }

        .matches-box-body {
          max-width: 614px;
          max-height: 400px;
          background: var(--v-base-base);
          border-radius: 0px 0px 5px 5px;
          border: 1px solid var(--v-primary-base);

          .match-item-desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 32px;
            color: #ffffff;
            width: 100%;
            height: 100%;
            background: url("/static/image/favourite/footballfield.webp");
            background-size: cover;
            background-position: center;
            //background-attachment: fixed;
            background-repeat: no-repeat;
          }
        }

      }
    }

    .matches-label {
      position: relative;
      border-left: 4px solid var(--v-primary-base);
      padding-left: 4px;
    }

    .matches-box {
      position: relative;
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;

      .matchUp {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;
      }

      .match-item {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        color: #ffffff;
        background: url("/static/image/favourite/footballfield.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-width: 460px;
        min-height: 320px;
        padding: 12px;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .league-name {
      font-size: 26px;
      text-align: center;
    }

    .match-date {
      font-size: 18px;
      text-align: center;
    }

    .match-team {
      display: flex;
      flex-direction: row;
      gap: 12px;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      width: 100%;
      font-size: 16px;

      .vs-team {
        font-size: 36px;
      }
    }

    .cricket-name-header {
      text-overflow: ellipsis;
      overflow: hidden;
      width: auto;
      white-space: nowrap;
    }
  }
}

@media (max-width: 599px) {
  .highlight-container {
    .highlight-wrapper {

      .matches-box {

        .match-item {
          min-width: 300px;
          min-height: 200px;

          .league-name {
            font-size: 16px;
          }

          .match-date {
            font-size: 14px;
          }

          .match-team {
            font-size: 16px;

            .vs-team {
              font-size: 26px;
            }
          }
        }
      }
    }
  }
}
</style>
