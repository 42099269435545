<template>
  <div class="highlight-container referral-home-banner-height d-flex">
    <div class="highlight-wrapper pt-4">
      <div class="third-row-container">
        <div>{{ $t(`label.referralProgram`) }}</div>
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown" class="matches-box first_box_match" style="overflow-x:hidden;">
        <router-link :to="{ name: routeName.REFERRAL_SUMMARY }" style="cursor: pointer;"><img alt="referral"
                                                                                              :src="`/static/image/referral/${language}/refer_mobile.jpg`" class="referral_img"/></router-link>
      </div>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="matches-box first_box_match" style="overflow-x:hidden;">
        <router-link :to="{ name: routeName.REFERRAL_SUMMARY }" style="cursor: pointer;"><img alt="referral"
                                                                                              :src="`/static/image/referral/${language}/refer_desktop.jpg`" class="referral_img"/></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {uiHelper} from "@/util";
import {SHARED} from "@/constants/constants";
import {ROUTE_NAME} from "@/constants/route.constants";

export default {
  name: "ReferralHomeBanner",
  data: () => ({
    language: uiHelper.getLanguage(),
    routeName: ROUTE_NAME
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn;
    }
  },
  methods: {
    routeToReferralPage() {
      if (this.isLoggedIn) {
        this.$router.push({
          name: ROUTE_NAME.REFERRAL_SUMMARY
        })
      } else {
        this.$parent.openLoginDialog()
      }
    }
  }
}
</script>

<style scoped>
.referral-home-banner-height {
  /*height: 500px;*/
}

@media (max-width: 599px) {
  .referral-home-banner-height {
    /*height: 254px;*/
  }
}

.referral_img {
  height: auto;
  width: 100%;
  aspect-ratio: 945/400;
}

@media (min-width: 599px) {
  .referral_img {
    height: 400px;
    aspect-ratio: 1240/400;
  }
}
</style>